import React, { useEffect, useState } from "react";
import Content from "../../src/layout/content/Content";
import Head from "../../src/layout/head/Head";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you import the CSS for the date picker
import { BlockHeadContent, BlockTitle, BlockHead, Block, BlockDes } from "../../src/components/Component";
import { Button, Card, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { getRequest, postRequestKYC } from "../api-service";

import idBack from "../assets/images/id-back.png";
import idFront from "../assets/images/id-front.png";

import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Backdrop, CircularProgress } from "@mui/material";

const UpdateKYCData = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    dob: null,
    address_1: "",
    address_2: "",
    country: null,
    state: null,
    zip_code: null,
    city: "",
    doc_front: "",
    doc_back: "",
  });

  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const [docFrontFile, setDocFrontFile] = useState(null);
  const [docBackFile, setDocBackFile] = useState(null);
  const [docFrontPreview, setDocFrontPreview] = useState([]);
  const [docBackPreview, setDocBackPreview] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [open, setOpen] = useState(false);

  function formatDate(date) {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    setOpen(true);
    const getUserKYC = async () => {
      try {
        const res = await getRequest("/kyc/get-user-kyc-data");
        const userKYCData = res.data.user_kyc_data[0];
        if (userKYCData.dob) {
          userKYCData.dob = new Date(userKYCData.dob);
        }
        setFormData(userKYCData);
        setUpdatedFormData(userKYCData);
        setOpen(false);
      } catch (error) {
        console.error("Error fetching user KYC data", error);
      }
    };
    getUserKYC();
  }, []);

  useEffect(() => {
    if (formData.country) {
      getStatesData();
    }
  }, [formData]);

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    setSelectedCountryId(countryId ? countryId : updatedFormData.country);
    setUpdatedFormData({ ...updatedFormData, country: countryId });

    getRequest(`/public/states?country_id=${countryId}`)
      .then((response) => {
        setStates(response.data.states);
      })
      .catch((error) => {
        console.error("There was an error fetching the states!", error);
      });
  };

  const handleStateChange = (e) => {
    setUpdatedFormData({ ...updatedFormData, state: e.target.value });
  };

  useEffect(() => {
    getRequest("/public/countries")
      .then((response) => {
        setCountries(response.data.countries);
      })
      .catch((error) => {
        console.error("There was an error fetching the countries!", error);
      });
  }, []);

  const getStatesData = () => {
    getRequest(`/public/states?country_id=${updatedFormData.country}`)
      .then((response) => {
        setStates(response.data.states);
      })
      .catch((error) => {
        console.error("There was an error fetching the states!", error);
      });
  };

  const onSubmit = async () => {
    setOpen(true);

    const formData = new FormData();

    formData.append("first_name", updatedFormData.first_name);
    formData.append("last_name", updatedFormData.last_name);
    formData.append("email", updatedFormData.email);
    formData.append("phone_no", updatedFormData.phone_no);

    if (updatedFormData.dob) {
      const formattedDob = formatDate(updatedFormData.dob); // Format dob to yyyy-mm-dd
      formData.append("dob", formattedDob);
    }

    formData.append("address_1", updatedFormData.address_1);
    formData.append("address_2", updatedFormData.address_2);
    formData.append("city", updatedFormData.city);
    // formData.append("state", data.state);
    formData.append("state", updatedFormData.state);

    // formData.append("country", data.country);
    formData.append("country", updatedFormData.country);

    formData.append("zip_code", updatedFormData.zip_code);
    // Append doc_front file if it exists
    if (docFrontFile) {
      formData.append("doc_front", docFrontFile);
    } else {
      formData.append("doc_front", updatedFormData.doc_front);
    }
    // Append doc_back file if it exists
    if (docBackFile) {
      formData.append("doc_back", docBackFile);
    } else {
      formData.append("doc_back", updatedFormData.doc_back);
    }

    try {
      await postRequestKYC("kyc/re-submit-kyc", formData)
        .then((res) => {
          setOpen(false);

          console.log("res", res);
          if (res.status === true) {
            Swal.fire({
              title: `${res.message}`,
              // text: "You clicked the button!",
              icon: "success",
              timer: 5000,
            });

            navigate("/");
          } else {
            Swal.fire({
              title: `${res.errors[0]}`,
              // text: "You clicked the button!",
              icon: "error",
              timer: 4000,
            });
          }
        })
        .catch((error) => {
          setOpen(false);

          console.error("Error:", error);
        });
    } catch (error) {
      Swal.fire("Error", "There was an error updating your KYC data", "error");
    }
  };

  useEffect(() => {
    if (docFrontFile) {
      setDocFrontPreview([{ name: docFrontFile.name, preview: URL.createObjectURL(docFrontFile) }]);
    } else {
      setDocFrontPreview([]);
    }
  }, [docFrontFile]);

  useEffect(() => {
    if (docBackFile) {
      setDocBackPreview([{ name: docBackFile.name, preview: URL.createObjectURL(docBackFile) }]);
    } else {
      setDocBackPreview([]);
    }
  }, [docBackFile]);

  const handleDropChange = (acceptedFiles, type) => {
    const selectedFile = acceptedFiles[0];

    if (type === 1) {
      setDocFrontFile(selectedFile);
    } else if (type === 2) {
      setDocBackFile(selectedFile);
    }
  };

  return (
    <React.Fragment>
      <Head title="KYC Forms" />
      <Content size="lg">
        <div className="kyc-app m-auto">
          <BlockHead size="lg" className="mx-auto mt-5" style={{ textAlign: "start" }}>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Begin your ID-Verification
              </BlockTitle>
              <p style={{ marginTop: "15px" }}>
                To comply with regulation each participant will have to go through identity verification (KYC/AML) to
                prevent fraud cases.
              </p>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <Card style={{ padding: "10px 20px" }}>
              <div className="nk-kycfm">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col sm="6">
                      <div className="nk-kycfm-head">
                        <div className="nk-kycfm-count">01</div>
                        <div className="nk-kycfm-title">
                          <h5 className="title">Personal Details</h5>
                          <p className="sub-title">Your simple personal information is required for identification</p>
                        </div>
                      </div>
                      <div className="nk-kycfm-content">
                        <Row className="g-4">
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  First Name <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  required
                                  value={updatedFormData.first_name}
                                  onChange={(e) =>
                                    setUpdatedFormData({ ...updatedFormData, first_name: e.target.value })
                                  }
                                  className="form-control form-control-lg"
                                />
                                {errors.firstName && <span className="invalid">{errors.firstName.message}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Last Name <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  required
                                  value={updatedFormData.last_name}
                                  onChange={(e) =>
                                    setUpdatedFormData({ ...updatedFormData, last_name: e.target.value })
                                  }
                                  className="form-control form-control-lg"
                                />
                                {errors.lastName && <span className="invalid">{errors.lastName.message}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Email Address <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="email"
                                  required
                                  value={updatedFormData.email}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, email: e.target.value })}
                                  className="form-control form-control-lg"
                                />
                                {errors.email && <span className="invalid">{errors.email.message}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Phone Number <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="number"
                                  maxLength={15}
                                  required
                                  value={updatedFormData.phone_no}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, phone_no: e.target.value })}
                                  className="form-control form-control-lg"
                                />
                                {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Date of Birth <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <DatePicker
                                  selected={updatedFormData.dob}
                                  onChange={(date) => setUpdatedFormData({ ...updatedFormData, dob: date })}
                                  className="form-control form-control-lg"
                                  placeholderText="Enter your date of birth"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  dateFormat="yyyy-MM-dd"
                                  maxDate={new Date()}
                                />
                                {errors.dob && <span className="invalid">{errors.dob.message}</span>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="nk-kycfm-head">
                        <div className="nk-kycfm-count">02</div>
                        <div className="nk-kycfm-title">
                          <h5 className="title">Address Details</h5>
                          <p className="sub-title">
                            Your address information is required for identification and verification
                          </p>
                        </div>
                      </div>
                      <div className="nk-kycfm-content">
                        <Row className="g-4">
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Address Line 1 <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  required
                                  value={updatedFormData.address_1}
                                  onChange={(e) =>
                                    setUpdatedFormData({ ...updatedFormData, address_1: e.target.value })
                                  }
                                  className="form-control form-control-lg"
                                />
                                {errors.address_1 && <span className="invalid">{errors.address_1.message}</span>}
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Address Line 2 <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  value={updatedFormData.address_2 === "undefined" ? "-" : updatedFormData.address_2}
                                  onChange={(e) =>
                                    setUpdatedFormData({ ...updatedFormData, address_2: e.target.value })
                                  }
                                  className="form-control form-control-lg"
                                />
                                {errors.address_2 && <span className="invalid">{errors.address_2.message}</span>}
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  City <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  required
                                  value={updatedFormData.city}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, city: e.target.value })}
                                  className="form-control form-control-lg"
                                />
                                {errors.city && <span className="invalid">{errors.city.message}</span>}
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Zip Code <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <input
                                  type="text"
                                  required
                                  value={updatedFormData.zip_code}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, zip_code: e.target.value })}
                                  className="form-control form-control-lg"
                                />
                                {errors.zip_code && <span className="invalid">{errors.zip_code.message}</span>}
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  Country <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <select
                                  className="form-control form-select"
                                  required
                                  value={updatedFormData.country}
                                  onChange={handleCountryChange}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                                {errors.country && <span className="invalid">{errors.country.message}</span>}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  State <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <select
                                  className="form-control form-select"
                                  required
                                  value={updatedFormData.state}
                                  onChange={handleStateChange}
                                >
                                  <option value="">Select State</option>
                                  {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {errors.state && <span className="invalid">{errors.state.message}</span>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <div className="nk-kycfm-head">
                      <div className="nk-kycfm-count">03</div>
                      <div className="nk-kycfm-title">
                        <h5 className="title">Upload Documents</h5>
                        <p className="sub-title">To verify your identity, please upload any of your document.</p>
                      </div>
                    </div>
                    <div className="nk-kycfm-content">
                      <Row className="g-4">
                        <Col sm="6">
                          <label className="form-label">
                            Document Front <span className="text-danger">*</span>
                          </label>
                          <Row>
                            <Col sm="6">
                              <div className="form-group">
                                <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, 1)}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        <aside className="text-center">
                                          <ul>
                                            {docFrontPreview.map((file) => (
                                              <li key={file.name}>
                                                <img
                                                  src={file.preview ? file.preview : `${updatedFormData.doc_front}`}
                                                  alt={file.name}
                                                  width="100"
                                                />
                                                <div>
                                                  <span className="dz-message-or d-block">or</span>
                                                  <Button size="sm" color="primary">
                                                    SELECT
                                                  </Button>{" "}
                                                </div>{" "}
                                              </li>
                                            ))}
                                            {docFrontPreview.length === 0 && (
                                              <div className="dz-message">
                                                <img src={updatedFormData.doc_front_link} width="100" />
                                                <span className="dz-message-or">or</span>
                                                <Button size="sm" color="primary">
                                                  SELECT
                                                </Button>
                                              </div>
                                            )}
                                          </ul>
                                        </aside>{" "}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </Col>
                            <Col sm="4" className="d-none d-sm-block hide-on-sm">
                              <div className="mx-md-4">
                                <img src={idFront} alt="ID Back" className="hide-on-sm" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm="6">
                          <label className="form-label">
                            Document Back <span className="text-danger">*</span>
                          </label>
                          <Row>
                            <Col sm="6">
                              <div className="form-group">
                                <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, 2)}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        <aside className="text-center">
                                          <ul>
                                            {docBackPreview.map((file) => (
                                              <li key={file.name}>
                                                <img src={file.preview} alt={file.name} width="100" />
                                                <div>
                                                  <span className="dz-message-or d-block">or</span>
                                                  <Button size="sm" color="primary">
                                                    SELECT
                                                  </Button>{" "}
                                                </div>
                                              </li>
                                            ))}
                                            {docBackPreview.length === 0 && (
                                              <div className="dz-message">
                                                <img src={updatedFormData.doc_back_link} width="100" />
                                                <span className="dz-message-or">or</span>
                                                <Button size="sm" color="primary">
                                                  SELECT
                                                </Button>
                                              </div>
                                            )}
                                          </ul>
                                        </aside>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </Col>
                            <Col sm="4" className="d-none d-sm-block hide-on-sm">
                              <div className="mx-md-4">
                                <img src={idBack} alt="ID Back" className="hide-on-sm" />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Row>

                  <hr />
                  <div className="form-group">
                    <div className="custom-control custom-control-xs custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="info-assure"
                        {...register("check", { required: "This field is required" })}
                      />
                      {errors.check && <span className="invalid">{errors.check.message}</span>}
                      <label className="custom-control-label" htmlFor="info-assure">
                        <span className="text-danger">*</span> All The Personal Information I Have Entered Is Correct.
                      </label>
                    </div>
                  </div>
                  <div className="nk-kycfm-action pt-2 text-center">
                    <button
                      disabled={formData.status === "0" || formData.status === "1" ? true : false}
                      type="submit"
                      className="btn btn-lg btn-primary"
                    >
                      Submit
                    </button>
                    {formData.status === "0" && (
                      <p style={{ color: "red", marginTop: "1%" }}>
                        Note: Your KYC submission is under review. Please be patient while we process it.
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </Card>
          </Block>
        </div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Content>
    </React.Fragment>
  );
};
export default UpdateKYCData;
