import React, { useContext, useEffect, useState } from "react";
import {
  AreaChart,
  AreaSeries,
  Area,
  Line,
  ChartZoomPan,
  TooltipArea,
  LinearXAxis,
  LinearXAxisTickSeries,
  LinearXAxisTickLabel,
  ChartTooltip,
} from "reaviz";
import { useTheme } from "../../../../layout/provider/Theme";
import { getRequest } from "../../../../api-service";
import { useParams } from "react-router";
import { Skeleton } from "@mui/material";
import { ReviewContext } from "../../../../App";

const largeDateData = [
  { id: 1, key: new Date("-"), data: "no investment" },
  { id: 2, key: new Date("-"), data: "no investment" },
];

const MyLineChart = ({ coinId: propCoinId }) => {
  const { coinId: paramCoinId } = useParams();
  const coinId = propCoinId || paramCoinId;
  const { reviewSubmitted } = useContext(ReviewContext);

  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  const transformData = (data) => {
    if (!Array.isArray(data)) return [];
    return data
      .map((item, index) => ({
        id: index + 1,
        key: new Date(item.date),
        data: item.data,
      }))
      .sort((a, b) => a.key - b.key);
  };

  const getCoinDataForGraph = () => {
    setLoading(true);
    getRequest(`/coin/get-investment-data?coin_id=${coinId}`)
      .then((res) => {
        if (res?.data?.investors) {
          const transformedData = transformData(res.data.investors);
          setGraphData(transformedData);
        } else {
          setGraphData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (coinId) {
      getCoinDataForGraph();
    }
  }, [coinId, reviewSubmitted]);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  if (loading) {
    return (
      <div>
        <Skeleton variant="rounded" height={350} width={"100%"} />
      </div>
    );
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <div style={{ position: "relative", marginTop: "0%", paddingLeft: "20px" }}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#f07d7d" />
            <stop offset="100%" stopColor="#f0d3d3" />
          </linearGradient>
        </defs>
      </svg>
      <AreaChart
        width={"calc(100% - 20px)"} // Adjust width to account for the margin
        height={350}
        data={graphData?.length === 0 ? largeDateData : graphData}
        zoomPan={<ChartZoomPan />}
        series={
          <AreaSeries
            interpolation="linear"
            area={<Area style={{ fill: "url(#gradient)", stroke: "red" }} />}
            line={<Line color="red" />}
            tooltip={
              <TooltipArea
                tooltip={
                  <ChartTooltip
                    followCursor={true}
                    showAnchorLine={true}
                    content={(data) => {
                      const tooltipData = graphData.find((d) => d.key.getTime() === data.key.getTime()) || {};
                      return (
                        <div>
                          <strong style={{ fontSize: "18px" }}>
                            {graphData?.length === 0 ? "No investment yet" : `$${tooltipData.data}`}
                          </strong>
                          <div style={{ fontSize: "12px" }}>
                            {graphData?.length === 0
                              ? "-"
                              : isToday(data.key)
                              ? data.key.toLocaleTimeString()
                              : data.key.toDateString()}
                          </div>
                        </div>
                      );
                    }}
                  />
                }
              />
            }
          />
        }
        xAxis={
          <LinearXAxis
            type="time"
            tickSeries={<LinearXAxisTickSeries label={<LinearXAxisTickLabel rotation={false} />} />}
          />
        }
      />
      <div style={{ position: "absolute", top: "50%", left: 0, transform: "translateY(-50%)", paddingRight: "20px" }}>
        {/* Y-axis label */}
        <svg width="110%" height={350} style={{ pointerEvents: "none" }}>
          <text
            x="20"
            y="48%"
            textAnchor="middle"
            fontSize="16px"
            fill={theme.palette?.text.primary}
            transform="rotate(-90, 20, 175)"
          >
            Subscriptions
          </text>
        </svg>
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {/* Label Section */}
        <div style={{ fontSize: "16px", color: theme.palette?.text.primary }}>
          {/* Replace with your labels or additional content */}
          <p>Time</p>
          {/* You can add more labels or additional content here */}
        </div>
      </div>
    </div>
  );
};

export default MyLineChart;
