import classNames from "classnames";
import { Info, MonitorPlay, Newspaper, ScrollText } from "lucide-react";
import { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Details from "./pages/Details/Details";
import Reports from "./pages/Reports/Reports";
import InfoBar from "./pages/Details/components/InfoBar";
import News from "./pages/News/News";
import Video from "./pages/Video/Video";
import Head from "../../layout/head/Head";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getCoinsList } from "../../http/get/getApis";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "../../layout/provider/Theme";

const CoinDetails = () => {
  const params = useParams();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [activeTab, setActivetab] = useState("1");

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => {
        //   setIsScrolling(false);
        // }, 200);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  const toggle = (number) => {
    setActivetab(number);
  };

  const {
    data: coinDetails,
    isLoading: coinDetailsIsLoading,
    isError: coinDetailsIsError,
  } = useQuery({
    queryKey: ["get-coins-details-byId", params],
    queryFn: () => getCoinsList({ id: params.coinId }),
  });

  return (
    <>
      <Head title={"Minority Startups"} />
      <div className={`p-2 pt-0 border bg-white my-4 mt-2`}>
        <Breadcrumb className="breadcrumb-arrow mt-1">
          <BreadcrumbItem>
            <Link to={"/"}>companies</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> Minority Startups </BreadcrumbItem>
        </Breadcrumb>
        <Nav
          tabs
          // className={isScrolling ? "glass-card" : ""}
          style={{
            position: "fixed",
            minWidth: isSmallScreen ? "84%" : "90%",
            marginLeft: "-0.8%",
            fontSize: isSmallScreen ? "0.5rem" : "",
            color: theme.skin === "dark" ? "#fff" : "#000",
            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",

            // backgroundColor: isScrolling ? "rgba(250, 245, 245,0.9)" : "#fff",
            zIndex: 1,
          }}
        >
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={`${classNames({ active: activeTab === "1" })} ${isSmallScreen ? "ms-0" : "ms-3"}`}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              <Info size={18} />{" "}
              <span
                className="ms-1 nav-li-color"
                style={{
                  color: activeTab === "1" ? "" : theme.skin === "dark" ? "#fff" : "#000",
                  width: isSmallScreen ? "30px" : "",
                }}
              >
                Coin Description
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              <Newspaper size={18} />{" "}
              <span
                className="ms-1 nav-li-color"
                style={{
                  color: activeTab === "2" ? "" : theme.skin === "dark" ? "#fff" : "#000",
                  width: isSmallScreen ? "30px" : "",
                }}
              >
                Company News
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              <MonitorPlay size={18} />{" "}
              <span
                className="ms-1 nav-li-color"
                style={{
                  color: activeTab === "3" ? "" : theme.skin === "dark" ? "#fff" : "#000",

                  width: isSmallScreen ? "30px" : "",
                }}
              >
                Video Information
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("4");
              }}
            >
              {" "}
              <ScrollText size={18} />{" "}
              <span
                className="ms-1 nav-li-color"
                style={{
                  color: activeTab === "4" ? "" : theme.skin === "dark" ? "#fff" : "#000",

                  width: isSmallScreen ? "30px" : "",
                }}
              >
                Reports
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{ minHeight: "70vh", marginTop: "60px", zIndex: 1500 }}>
          <TabPane tabId="1">
            <Details coinDetails={coinDetails?.data?.coin} />
          </TabPane>
          <TabPane tabId="2">
            <News coinDetails={coinDetails?.data?.coin} />
          </TabPane>
          <TabPane tabId="3">
            <Video coinDetails={coinDetails?.data?.coin} />
          </TabPane>
          <TabPane tabId="4">
            <Reports coinDetails={coinDetails?.data?.coin} />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};
export default CoinDetails;
