import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Head from "../../../../layout/head/Head";
import Header from "../../../../layout/header/Header";
import Footer from "../../../../layout/footer/Footer";
import AppRoot from "../../../../layout//global/AppRoot";
import AppMain from "../../../../layout//global/AppMain";
import AppWrap from "../../../../layout/global/AppWrap";
import { Container } from "reactstrap";
import { getCookie } from "../../../../utils/Utils";
import Cookies from "js-cookie";
import { getRequest } from "../../../../api-service";
import { CircularProgress } from "@mui/material";
const Layout = ({ title, app, ...props }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const isAuthenticated = !!getCookie("authToken");

  //   if (!isAuthenticated) {
  //     const handleUnauthorizedAccess = () => {
  //       navigate(process.env.REACT_APP_ACCOUNT_URL);
  //     };

  //     window.addEventListener("click", handleUnauthorizedAccess);

  //     return () => {
  //       window.removeEventListener("click", handleUnauthorizedAccess);
  //     };
  //   }
  // }, [navigate]);

  const [authChecking, setAuthChecking] = useState(true);

  // const [loading, setLoading] = useState(true);
  // const [role, setRole] = useState([]);

  // useEffect(() => {
  //   const fetchRoleFromAPI = async () => {
  //     try {
  //       const authToken = getCookie("authToken");
  //       const response = await fetch(${process.env.REACT_APP_API_URL}public/get-role, {
  //         method: "POST",
  //         headers: {
  //           authToken: authToken,
  //         },
  //       });

  //       if (response.ok) {
  //         const responseData = await response.json();
  //         if (responseData.status) {
  //           const roleId1 = responseData.data.role.id;
  //           console.log(roleId1);
  //           setRole(roleId1);

  //           if(roleId1 != "2")
  //           window.location = process.env.REACT_APP_USER_URL;

  //         }
  //       } else {
  //         console.log("Error fetching role:", response.statusText);
  //       }
  //     } catch (error) {
  //       console.error("Fetch error:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchRoleFromAPI();
  // }, []);

  // useEffect(() => {
  //   let at = Cookies.get("authToken");
  //   if (!at) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });
  //     window.location = process.env.REACT_APP_ACCOUNT_URL;
  //     // alert("checked");
  //   } else {
  //     setAuthChecking(false);
  //     Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
  //   }
  // }, []);

  // if (authChecking)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh",
  //       }}
  //     >
  //       <div className="text-center">
  //         <CircularProgress color="error" />
  //         <h4> Please wait, we are redirecting </h4>
  //       </div>
  //     </div>
  //   );

  return (
    <>
      <Head title={!title ? "Loading" : title} />
      <AppRoot>
        <AppMain>
          <AppWrap>
            <div className="">
              <Header fixed />
              <Outlet />
              <Footer />
            </div>
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};

export default Layout;
