import React, { useContext, useEffect, useState } from "react";
import { Star } from "lucide-react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from "reactstrap";
import CoinsGraph from "./CoinsGraph";
import BuySellModal from "../../../../cart/components/BuySellModal";
import { getRequest, postRequest } from "../../../../../api-service";
import { useNavigate, useParams } from "react-router";
import { FaStar } from "react-icons/fa";
import { Backdrop, CircularProgress, Grid, Skeleton, useMediaQuery } from "@mui/material";
import { ReviewContext } from "../../../../../App";
import MyLineChart from "../../graphs/CoinInvestmentGraph";
import { useTheme } from "../../../../../layout/provider/Theme";
import Cookies from "js-cookie";

const ProductCard = ({ coinDetails }) => {
  const theme = useTheme();

  const { reviewSubmitted } = useContext(ReviewContext);
  const [isOpen, setIsOpen] = useState(false);
  const [reviewDetails, setReviewDetails] = useState({ starcount: 0, totalreviewcount: 0 });
  const { coinId } = useParams();
  const [loading, setLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({ show: false, message: "", reapply: false, color: "" });
  const [open, setOpen] = useState(false); // State for backdrop
  const navigate = useNavigate();

  // const [kycApproved, setKycApproved] = useState(false);
  // localStorage.setItem("kycApproved", kycApproved);

  const KYCApproved = localStorage.getItem("kycApproved") ? localStorage.getItem("kycApproved") : undefined;

  //   useEffect(() => {
  //   if (!authToken) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });
  //   }
  // }, [authToken]);

  // Function to handle KYC checks

  const authToken = Cookies.get("authToken");
  const handleKYCCheck = async () => {
    if (!authToken) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });

      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      if (KYCApproved === "true") {
        setIsOpen(true);
      }
      if (KYCApproved === "false" || KYCApproved === undefined) {
        setOpen(true);

        try {
          const res = await getRequest(`/kyc/check-kyc`);
          const kycCheck = res.data?.kyc_data[0];
          setOpen(false);

          if (kycCheck.kyc_submitted === false) {
            navigate("/user-kyc");
            return;
          }

          if (kycCheck.kyc_submitted === true && kycCheck.status === "approved") {
            setIsOpen(true);
            localStorage.setItem("kycApproved", true);

            // setKycApproved(true);
          }

          if (kycCheck.kyc_submitted === true && kycCheck.status === "pending") {
            showPendingMessage("Your KYC submission is under review. Please be patient while we process it.");
            return;
          }

          if (kycCheck.kyc_submitted === true && kycCheck.status === "rejected") {
            showRejectedMessage(kycCheck.reject_message);
            return;
          }
        } catch (error) {
          setOpen(false);
          console.error("Error checking KYC status:", error);
        }
      }
    }
  };

  // Function to show rejected KYC message
  const showRejectedMessage = (message) => {
    setModalInfo({ show: true, message: message, reapply: true, color: "error" });
  };

  // Function to show pending KYC message
  const showPendingMessage = (message) => {
    setModalInfo({ show: true, message: message, reapply: false, color: "warning" });
  };

  // Function to fetch review details
  const getReviewDetails = async () => {
    const payload = { coin_id: coinId };
    try {
      const response = await postRequest(`/coin/get-review`, payload);
      setReviewDetails(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error fetching review details:", error);
    }
  };

  // Close Modal
  const handleCloseModal = () => {
    setModalInfo({ ...modalInfo, show: false });
  };

  // Handle reapply for KYC
  const handleReapply = () => {
    handleCloseModal();
    navigate("/update-kyc");
  };

  // Fetch review details on mount and when reviewSubmitted changes
  useEffect(() => {
    // setKycApproved(KYCApproved);
    getReviewDetails();
  }, [reviewSubmitted, coinId]);

  // Render stars for reviews
  const renderStars = (count) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(<FaStar key={i} size={22} color={i <= count ? "#FFC94A" : "#E4E5E9"} />);
    }
    return stars;
  };

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
        clearTimeout(timeoutId);
        // timeoutId = setTimeout(() => {
        //   setIsScrolling(false);
        // }, 200);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:400px)");
  const isSmallScreen1 = useMediaQuery("(max-width:300px)");

  return (
    <>
      {loading ? (
        <div className="d-block justify-content-start align-items-start responsiveFlex" style={{ gap: "25px" }}>
          <Grid container>
            <Grid item xs={12}>
              <div className="p-4 py-2" style={{ minWidth: "50%" }}>
                <div className="d-flex">
                  <Skeleton variant="circular" sx={{ mr: 2, mt: 2 }} width={50} height={50} />

                  <Skeleton variant="text" sx={{ fontSize: "3rem" }} width={"70%"} />
                </div>
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <div className="me-3">{renderStars(0)}</div>
                  <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={80} />
                </div>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"90%"} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"90%"} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"60%"} />
                <Skeleton variant="rounded" sx={{ fontSize: "2rem", mt: 2 }} width={"35%"} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ minWidth: "50%", marginTop: "40px" }} className="p-4 pt-0">
                <div className="d-flex justify-content-between">
                  <Skeleton variant="rounded" sx={{ fontSize: "2rem" }} width={"20%"} />
                  <Skeleton variant="rounded" sx={{ fontSize: "2rem" }} width={"50%"} />
                </div>
                <div>
                  <Skeleton variant="rounded" sx={{ mt: 2, height: "175px" }} width={"100%"} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {coinDetails?.map((coin) => (
            <>
              {isScrolling ? (
                <div
                  // color="light"
                  className={` ${isScrolling ? "navbar-transition" : ""}`}
                  light
                  expand="md"
                  style={{
                    color: theme.skin === "dark" ? "#fff" : "#000",

                    backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
                    position: "fixed",
                    minWidth: isSmallScreen ? "84%" : "90%",
                    marginLeft: "-0.8%",
                    fontSize: isSmallScreen ? "0.5rem" : "",
                    borderBottom: "1px solid #c9bcbb",
                    // backgroundColor: isScrolling ? "rgba(250, 245, 245,0.9)" : "#fff",
                    zIndex: 1,
                    marginTop: "-0.35%",
                    padding: "10px",
                    paddingBottom: "0",
                  }}
                >
                  <div
                    className={`${isSmallScreen ? "d-block" : "d-flex"}  justify-content-start`}
                    style={{
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      fontSize: "18px",
                      fontWeight: "600",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <img src={coin.coin_logo} alt="" height={40} width={40} />
                      <p style={{ minWidth: "fit-content", fontSize: "22px", marginLeft: "10px" }}>{coin.coin_name}</p>
                    </div>
                    <p style={{ margin: isSmallScreen ? "-4% 21%" : "5px 1%" }}>
                      <span style={{ fontSize: "14px" }}>$</span>
                      {coin.price}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                key={coin.id}
                className="d-block  justify-content-start align-items-start responsiveFlex mt-4"
                style={{ gap: "10px", marginTop: "10%" }}
              >
                {/* <div className=" d-flex p-4 py-2 mt-2" style={{ minWidth: "50%" }}> */}
                <Row>
                  <Col xs={12} sm={9}>
                    <div className="  p-4 py-2 mt-2">
                      <div className="d-flex mt-2 mb-2">
                        <img src={`${coin?.coin_logo}`} alt="" width={50} height={50} />
                        <h2 className="fw-bold ms-2"> {coin.coin_name} </h2>
                        <h4
                          style={{
                            display: isSmallScreen ? "none" : "",
                            margin: isSmallScreen1 ? "17% 2%" : "1.5% 2%",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>$</span>
                          {coin.price}
                        </h4>
                      </div>
                      {isSmallScreen && (
                        <h4
                          style={{
                            margin: isSmallScreen ? "0% 30%" : "5px 15%",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>$</span>
                          {coin.price}
                        </h4>
                      )}
                      <div className="d-flex justify-content-start align-items-center mt-2">
                        <div className="me-3">{renderStars(reviewDetails.starcount)}</div>
                        <p>({reviewDetails.totalreviewcount ? reviewDetails.totalreviewcount : "0"} Reviews)</p>
                      </div>
                      <p className="mt-3 limit-5" style={{ width: "100%", overflowWrap: "break-word" }}>
                        {coin.description}
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={3} className="text-end ml-auto" style={{}}>
                    <div className="  p-4 py-2 mt-2" style={{ marginLeft: "auto", textAlign: "end" }}>
                      <Button color="primary" className="mt-4 ml-auto" onClick={handleKYCCheck}>
                        {/* <Star size={18} fill="white" /> */}
                        <span className="ms-0">Subscribe Now</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* </div> */}
                <hr />
                <div style={{ minWidth: "50%", margin: "0 5%" }} className="p-4 py-0 pt-0">
                  {/* <CoinsGraph /> */}
                  <MyLineChart />
                </div>
              </div>
            </>
          ))}
        </>
      )}
      <BuySellModal isOpen={isOpen} close={() => setIsOpen(false)} />

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="error" />
      </Backdrop>

      <Modal isOpen={modalInfo.show} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>{modalInfo.reapply ? "KYC Rejected" : "KYC Pending"}</ModalHeader>
        <ModalBody>{modalInfo.message}</ModalBody>
        <ModalFooter>
          {modalInfo.reapply && (
            <Button color="success" onClick={handleReapply}>
              Reapply
            </Button>
          )}
          <Button color="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProductCard;
