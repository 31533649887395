import { useEffect, useState } from "react";
import { getCookie } from "../../../utils/Utils";
import { Paper, Skeleton } from "@mui/material";
import { getRequest } from "../../../api-service";
import { Autocomplete, TextField } from "@mui/material";
import { useTheme } from "../../../layout/provider/Theme";

const Title = ({ toggle, bool }) => {
  const userName = getCookie("username", "");
  const theme = useTheme();

  const greetingMessages = [
    `Welcome ${userName}`,
    // `Hello ${userName}! Let's invest in companies.`,
    // `Hi there ${userName} Ready to start investing?`,
  ];

  const randomGreeting = greetingMessages[Math.floor(Math.random() * greetingMessages.length)];

  const [loading, setLoading] = useState(true);
  const [data, setCoinData] = useState([]);
  const [searchText, setSearchText] = useState("");
  // console.log("searchText", searchText);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleOptionSelected = (event, value) => {
    setSearchText(""); // Reset the text field

    if (value) {
      addToPortfolioHandler(value.id);
      setSearchText(""); // Reset the text field
    }
  };

  const addToPortfolioHandler = (id) => {
    window.location.href = `/companies/${id}`;
    setSearchText(id); // Reset the text field
  };

  const getCoinsData = () => {
    getRequest(`/coin/get-coin?search_keyword=${searchText}`).then((res) => {
      setCoinData(res?.data?.coin || []); // Ensure data is always an array
    });
  };

  useEffect(() => {
    getCoinsData();
  }, [searchText]);

  const PaperComponent = (props) => (
    <Paper
      {...props}
      sx={{
        backgroundColor: theme.skin === "dark" ? "rgba(40, 47, 56 ,0.8)" : "rgba(255, 255, 255 ,0.9)",
        border: "none",
        borderRadius: "24px",
        color: theme.skin === "dark" ? "#fff" : "#000",
        padding: "0 15px",
        cursor: "pointer",
        marginTop: "0.3%",
      }}
    />
  );

  return (
    <>
      <section className="d-flex justify-content-center align-items-center responsiveDiv mt-4">
        <div style={{ marginTop: "2%" }}>
          <h3 className="mt-1 d-flex text-center mt-4 mb-4" style={{ color: "#fff" }}>
            {randomGreeting}
          </h3>
        </div>
      </section>

      <div className="d-md-flex justify-content-center align-items-center m-auto mt-4 mb-4">
        <Autocomplete
          className={theme.skin === "dark" ? "homepage-searchBarDark" : "homepage-searchBar"}
          fullWidth
          id="combo-box-demo"
          options={data || []} // Ensure options is always an array
          getOptionLabel={(option) => option?.coin_name}
          onChange={handleOptionSelected}
          inputValue={searchText}
          onInputChange={(event, newInputValue) => {
            setSearchText(newInputValue);
          }}
          PaperComponent={PaperComponent}
          sx={{
            width: 200,
            borderRadius: "24px",
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label="Search Coin"
              InputLabelProps={{
                style: {
                  fontSize: "0.8rem", // Adjust the label font size as needed
                  color: "grey", // Label color
                },
              }}
              sx={{
                height: "40px", // Set the desired height
                "& .MuiInputBase-root": {
                  height: "40px", // Set the input field height
                  padding: "10px auto",
                  borderRadius: "2%",
                  border: "none", // Remove border
                  color: "#000",
                },
                "& .MuiFormLabel-root": {
                  fontSize: "0.5rem", // Adjust the label font size
                  margin: "-5px auto",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove border
                },
                // "& .MuiInputLabel-root": {
                //   position: "absolute",
                //   top: "68%",
                //   left: "10px",
                //   transform: "translateY(-60%)",
                //   transition: "all 0.3s",
                // },
                "& .MuiInputLabel-root.Mui-focused": {
                  // top: "-3%",
                  transform: "translateY(0)",
                  display: "none",

                  // display: searchText ? "none" : "",
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} className="homepage-searchBar-option d-flex" style={{ zIndex: -1000 }}>
              <img src={option.coin_logo} alt="" height={30} width={30} style={{ marginRight: "10px" }} />
              <p> {option.coin_name}</p>
            </li>
          )}
          componentsProps={{
            popupIndicator: {
              sx: {
                display: "none",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default Title;
