import React from "react";
import classNames from "classnames";
import FixedHomeLogo from "../../FixedHomeLogo";

function AppWrap({ className, ...props }) {
  const compClass = classNames({
    "nk-wrap": false,
    [`${className}`]: className,
  });
  return (
    <>
      <div className={compClass}>{props.children}</div>
      <FixedHomeLogo />
    </>
  );
}

export default AppWrap;
