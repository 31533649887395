import Head from "../../layout/head/Head";
import CryptoTable from "./components/CryptoTable";
import MarketCap from "./components/MarketCap";
import PriceOverview from "./components/PriceOverview";
import Title from "./components/Title";
import { useState } from "react";

const Home = () => {
  const [bool, setBool] = useState(true);
  return (
    <>
      <Head title={"CoinExchange"} />
      <section style={{ marginTop: "85px" }}>
        <Title toggle={() => setBool(!bool)} bool={bool} />
        {bool && <PriceOverview />}
        {bool && <MarketCap />}
        <CryptoTable />
      </section>
    </>
  );
};
export default Home;
