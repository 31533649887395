import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../api-service";
import MyLineChart from "../../../Details/pages/graphs/CoinInvestmentGraph";
import { useTheme } from "../../../../layout/provider/Theme";

const Holdings = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const theme = useTheme();
  const [investments, setInvestments] = useState([]);
  console.log("investments", investments);
  const [showAll, setShowAll] = useState(false);
  const [selectedCoinId, setSelectedCoinId] = useState(null);
  const [selectedCoinName, setSelectedCoinName] = useState(null);

  const [loading, setLoading] = useState(true);
  const getUserInvestments = () => {
    getRequest("/coin/get-user-investment").then((res) => {
      const coins = res.data.coin;
      if (res.status === false) {
        setSelectedCoinId([]);
        setLoading(false);
      }
      setInvestments(coins);
      if (coins?.length > 0) {
        setSelectedCoinId(coins[0].id);
        setSelectedCoinName(coins[0].coin_name);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getUserInvestments();
  }, []);

  const displayedInvestments = showAll ? investments : investments?.slice(0, 3);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5}>
          <Card
            sx={{ mt: isSmallScreen ? 0 : 5 }}
            className={`invstmnt-card  ${theme.skin === "dark" ? "dark-modeMUI" : ""}`}
          >
            <Typography sx={{ fontSize: "19px", fontWeight: 800 }}>Holdings</Typography>
            {investments?.length === undefined && <Typography>No subscription Available</Typography>}

            <CardContent>
              <TableContainer
                sx={{ maxHeight: "271px", overflowY: "auto", maxWidth: isSmallScreen ? "200px" : "100%" }}
              >
                <Table size="medium" className={` ${theme.skin === "dark" ? "dark-modeMUI" : ""}`}>
                  <TableBody>
                    {loading
                      ? Array.from({ length: 3 })?.map((_, index) => (
                          <TableRow key={index} style={{ textAlign: "center" }}>
                            {Array.from({ length: 2 })?.map((_, cellIndex) => (
                              <TableCell
                                key={cellIndex}
                                // sx={{
                                //   borderBottom: "none",
                                // }}
                              >
                                <Skeleton variant="text" sx={{ fontSize: "2.5rem" }} width={"100%"} />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : displayedInvestments?.map((investment) => (
                          <TableRow
                            key={investment.id}
                            onClick={() => {
                              setSelectedCoinId(investment.id);
                              setSelectedCoinName(investment.coin_name);
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor: selectedCoinId === investment.id ? "#fff1f1" : "transparent",
                              color: selectedCoinId === investment.id ? "#000" : "",
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "start",
                                  minWidth: "200px",
                                  gap: 15,
                                }}
                              >
                                <img
                                  src={investment.coin_logo}
                                  alt={investment.coin_name}
                                  style={{ width: "35px", height: "35px", marginLeft: "0px" }}
                                />
                                <div style={{ textAlign: "start" }}>
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 700,
                                      color:
                                        theme.skin === "dark"
                                          ? selectedCoinId === investment.id
                                            ? "#000"
                                            : "#fff"
                                          : "#000",
                                    }}
                                  >
                                    {investment.coin_name}
                                  </div>
                                  <div style={{ fontSize: "12px", color: "#999999" }}>{investment.coin_symbol}</div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color:
                                  theme.skin === "dark" ? (selectedCoinId === investment.id ? "#000" : "#fff") : "#000",
                              }}
                            >
                              ${investment.total_investment}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {investments?.length > 3 && (
                <Button color="error" onClick={() => setShowAll(!showAll)}>
                  {showAll ? "Show Less" : "View More"}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          sx={{ mt: isSmallScreen ? 0 : 4, display: investments?.length === undefined ? "none" : "" }}
        >
          {selectedCoinId ? (
            <>
              <Typography variant="h5">{selectedCoinName}</Typography>
              <MyLineChart coinId={selectedCoinId} />
            </>
          ) : (
            <Skeleton variant="rounded" sx={{ mt: 2 }} height={400} width={"100%"} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Holdings;
