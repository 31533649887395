import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import "./App.css";
import { ChatContextProvider } from "./pages/app/chat/ChatContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { createContext, useState } from "react";

import backgroundHome from "./images/backgroundHome.png";
import BGImg from "./images/BGImg.png";
import BG from "./images/BG.jpg";

import { useLocation } from "react-router";
import { useMediaQuery } from "@mui/material";

const queryClient = new QueryClient();

export const ReviewContext = createContext();

const App = () => {
  const [reviewSubmitted, setReviewSubmitted] = useState(null);
  const location = useLocation();
  const isLargeScreen = useMediaQuery("(min-width:961px) and (max-width:1480px)");

  const isxlScreen = useMediaQuery("(min-width:1490px)");
  const is4KScreen = useMediaQuery("(min-width:3840px)");

  const isMacBookAir =
    navigator.userAgent.includes("Macintosh") && window.devicePixelRatio === 2 && window.screen.height === 900;

  const style = {
    backgroundImage: `url(${BGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: isLargeScreen ? "35vh" : isxlScreen ? "50vh" : "45vh", // 35% for MacBook Air, otherwise 50%
    width: "100%",
    marginTop: "-35px",
  };

  if (is4KScreen) {
    console.log("Screen size is 4K or higher");
  }

  const getValueForNewData = (data) => {
    console.log("data===========>", data);
    setReviewSubmitted(data);
  };

  return (
    <ReviewContext.Provider value={{ getValueForNewData, reviewSubmitted }}>
      <ThemeProvider>
        <ChatContextProvider>
          <QueryClientProvider client={queryClient}>
            <div style={location.pathname === "/" ? style : {}}>
              <section style={{ marginTop: "65px", margin: "0 5%" }}>
                <Router />
              </section>
            </div>
          </QueryClientProvider>
        </ChatContextProvider>
      </ThemeProvider>
    </ReviewContext.Provider>
  );
};

export default App;
