import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

import Layout from "../pages/app/file-manager/components/Layout";
import Home from "../pages/home/Home";
import MarketCapDetails from "../pages/marketCapDetails/MarketCapDetails";
import Portfolio from "../pages/cart/Portfolio";
import CoinDetails from "../pages/Details/CoinDetails";
import Error404Classic from "../pages/error/404-classic";
import Chat from "../pages/app/chat/Chat";
import Converter from "../pages/Converter/Converter";
import SimpleLayout from "../layout/Index";
import UserKYC from "../kyc/UserKYC";
import UpdateKYCData from "../kyc/UpdateKYCData";
import MyLineChart from "../pages/Details/pages/graphs/CoinInvestmentGraph";
import { getRequest } from "../api-service";

const Pages = () => {
  const location = useLocation();
  const authToken = Cookies.get("authToken");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const checkKYCStatus = async () => {
      try {
        const res = await getRequest(`/kyc/check-kyc`);
        // console.log("res index", res);
        const kycCheck = res.data?.kyc_data[0];
        // console.log("kycCheck", kycCheck);
        // console.log("kycindex", kycCheck?.status);

        if (kycCheck?.status !== "approved") {
          localStorage.setItem("kycApproved", false);
          console.log("kycindex", kycCheck?.status);
          // setKycApproved(true);
        }

        if (!authToken) {
          localStorage.setItem("kycApproved", false);

          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
          Cookies.set("currentURL", window.location.href, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
        }
      } catch (error) {
        console.error("Error checking KYC status:", error);
      }
    };

    checkKYCStatus();
  }, [authToken]);
  return (
    <Routes>
      <Route path="/" element={<SimpleLayout />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="/companies/:coinId" element={<CoinDetails />} />
        <Route path="/trending" element={<MarketCapDetails />} />
        <Route path="/recently-added" element={<MarketCapDetails />} />
        <Route path="/most-visited" element={<MarketCapDetails />} />
        {authToken ? (
          <>
            <Route path="/portfolio" element={<Portfolio />} />

            <Route path="/forms" element={<Chat />} />
            {/* <Route path="/converter" element={<Converter />} /> */}
            <Route path="/user-kyc" element={<UserKYC />} />
            <Route path="/update-kyc" element={<UpdateKYCData />} />
            <Route path="/graph" element={<MyLineChart />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Route>
      <Route path="*" element={<Error404Classic />} />
    </Routes>
  );
};

export default Pages;
