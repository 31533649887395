import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getRequest, postRequest } from "./api-service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Sparkline from "./components/sparkLine/Sparkline";
import { useTheme } from "./layout/provider/Theme";

const getRandomData = (largeData) => {
  const randomData = Array.from({ length: largeData ? 60 : 8 }, () => Math.floor(Math.random() * 49));
  return randomData;
};

const getRandomColor = () => {
  const colors = ["#FF0000", "#008000"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const SparklineComponent = ({ width = 100, height = 50, largeData = false }) => {
  const randomData = getRandomData(largeData);
  const randomColor = getRandomColor();

  return <Sparkline data={randomData} width={width} height={height} color={randomColor} />;
};

const TempTable = ({ dataType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    { id: "coin_info", label: "Name", minWidth: 200, align: "left" },
    { id: "price", label: "Price", minWidth: 70, align: "center", format: (value) => `$${value.toFixed(2)}` },
    { id: "one_h", label: "1h%", minWidth: 70, align: "center", format: (value) => `${value}%` },
    { id: "twenty_four_h", label: "24h%", minWidth: 70, align: "center", format: (value) => `${value}%` },
    { id: "seven_d", label: "7d%", minWidth: 70, align: "center", format: (value) => `${value}%` },
    {
      id: "market_cap",
      label: "Market Cap",
      minWidth: 120,
      align: "center",
      format: (value) => value.toLocaleString(),
    },
    { id: "volume", label: "Volume", minWidth: 100, align: "center", format: (value) => value.toLocaleString() },
    {
      id: "circulation_supply",
      label: "Circulating Supply",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString(),
    },
  ];

  const getCoinsUpdatedata = () => {
    let payload = {};

    switch (location.pathname) {
      case "/trending":
        payload = { id: 0, page: page, limit: rowsPerPage, earch_keyword: searchQuery };
        break;
      case "/recently-added":
        payload = { id: 1, page: page, limit: rowsPerPage, earch_keyword: searchQuery };
        break;
      case "/most-visited":
        payload = { id: 2, page: page, limit: rowsPerPage, earch_keyword: searchQuery };
        break;
      default:
        payload = {};
    }

    postRequest("/coin/recently-added", payload)
      .then((res) => {
        switch (location.pathname) {
          case "/trending":
            setTableData(res.data.trending_coins);
            break;
          case "/recently-added":
            setTableData(res.data.recently_added_coins);
            break;
          case "/most-visited":
            setTableData(res.data.most_viewed_coins);
            break;
          default:
            setTableData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching coins:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCoinsUpdatedata();
  }, [location.pathname, searchQuery, rowsPerPage, page]);

  useEffect(() => {
    if (dataType === "portfolioTable") {
      getRequest(`/coin/get-user-investment`).then((res) => {
        setTableData(res.data.coin);
      });
    }
  }, [dataType]);

  const handleRowClick = (id) => {
    navigate(`/companies/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredData = tableData?.filter((row) => row.coin_name?.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <>
      <TextField
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{ mb: 2, width: "20%" }}
      />
      <TableContainer sx={{ maxHeight: 540, border: "none" }}>
        <Table stickyHeader aria-label="sticky table" size="small" sx={{ textAlign: "center" }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "700",
                    fontSize: "15px",
                    borderBottom: "none",
                    color: theme.skin === "dark" ? "#fff" : "#000",
                    backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 20 }).map((_, index) => (
                  <TableRow key={index} style={{ textAlign: "center" }}>
                    {Array.from({ length: columns?.length }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={"100%"} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : filteredData?.map((row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ textAlign: "center", border: "none" }}
                    onClick={() => handleRowClick(row.id)}
                    className="tableRow"
                  >
                    {columns.map((column) => {
                      const value = column.id === "srNo" ? index + 1 : row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            textAlign: "center",
                            cursor: "pointer",
                            borderBottom: "none",
                            color: theme.skin === "dark" ? "#fff" : "#000",
                          }}
                        >
                          {column.id === "coin_info" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                                minWidth: "200px",
                                gap: 15,
                              }}
                            >
                              <img
                                src={`https://api.silocloud.io/get-file-content/public/${row.coin_logo}`}
                                alt={row.coin_name}
                                style={{ width: "35px", height: "35px", marginLeft: "0px" }}
                              />
                              <div style={{ textAlign: "start" }}>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 700,
                                    color: theme.skin === "dark" ? "#fff" : "#000",
                                  }}
                                >
                                  {row.coin_name}
                                </div>
                                <div style={{ fontSize: "12px", color: "#999999" }}>{row.coin_symbol}</div>
                              </div>
                            </div>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TempTable;
