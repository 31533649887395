import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { Tag } from "lucide-react";
import { getRequest, postRequest } from "../../../api-service";
import styles from "./MarketCap.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import { Tooltip } from "chart.js";
import Sparkline from "../../../components/sparkLine/Sparkline";
import AdjustIcon from "@mui/icons-material/Adjust";
import "../../../../src/css/main.css";
import { useTheme } from "../../../layout/provider/Theme";

const columns = [
  // { id: "srNo", label: "Sr No.", minWidth: 80, align: "center" },
  { id: "coin_info", label: "Name", minWidth: 200, align: "left" }, // Combined column
  { id: "price", label: "Price", minWidth: 70, align: "center", format: (value) => `$${value.toFixed(2)}` },
  { id: "one_h", label: "1h%", minWidth: 70, align: "center", format: (value) => `${value}%` },
  { id: "twenty_four_h", label: "24h%", minWidth: 70, align: "center", format: (value) => `${value}%` },
  { id: "seven_d", label: "7d%", minWidth: 70, align: "center", format: (value) => `${value}%` },
  { id: "market_cap", label: "Market Cap", minWidth: 120, align: "center", format: (value) => value.toLocaleString() },
  { id: "volume", label: "Volume", minWidth: 100, align: "center", format: (value) => value.toLocaleString() },
  {
    id: "circulation_supply",
    label: "Circulating Supply",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString(),
  },
  // { id: "graph", label: "Graph", minWidth: 100, align: "center" },
];

const CryptoTable = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [coinData, setCoinData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(true);

  const handleRowClick = (coinId) => {
    navigate(`/companies/${coinId}`);

    const payload = {
      coin_id: coinId,
    };

    postRequest("/coin/increment-view", payload).then((response) => {
      console.log("response", response);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCoinData = () => {
    // getRequest(`/coin/get-coin?page=${page}&limit=${rowsPerPage}&search_keyword=${searchKeyword}`).then((res) => {
    getRequest(`/coin/get-coin`)
      .then((res) => {
        setCoinData(res.data);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);

        console.log("error while fetching", res);
      });
  };

  useEffect(() => {
    getCoinData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
    setPage(0); // Reset to first page on new search
  };

  const data = [9, 1, 14, 30, 34, 14, 29, 30, 20, 33, 38, 25, 4];
  // Placeholder for graph rendering
  const renderGraph = () => {
    // Replace with actual graph component or image
    return (
      <div style={{ width: "50px", height: "30px", color: "blue" }}>
        <Sparkline data={data} />
      </div>
    );
  };

  const [blink, setBlink] = useState(false);

  setTimeout(() => {
    setBlink(!blink);
  }, 400);

  return (
    <div className={`my-4 mt-2  bg-white ${styles.responsiveSection} `} style={{ textAlign: "center", margin: "auto" }}>
      <h5 className="d-flex justify-content-start align-items-center rounded my-2 mt-2 mb-2">
        {/* <Tag className="me-2" strokeWidth={2} absoluteStrokeWidth /> */}
        <AdjustIcon color={blink === true ? "error" : ""} sx={{ marginRight: "10px", ml: 2, mt: 2 }} />
        <span style={{ marginTop: "18px" }}> Live updates of all active crypto companies</span>
      </h5>

      {/* <div className="d-flex pb-2">
        <TextField
          sx={{ padding: "0px" }}
          variant="outlined"
          placeholder="Search…"
          value={searchKeyword}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div> */}

      <div className="p-3 ">
        {/* <Paper sx={{ width: "100%", overflow: "hidden", border: "none" }}> */}
        <TableContainer sx={{ maxHeight: 540, border: "none" }}>
          <Table stickyHeader aria-label="sticky table" size="small" sx={{ textAlign: "center" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      // textAlign: "start",
                      fontWeight: "700",
                      fontSize: "15px",
                      borderBottom: "none",
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from({ length: 20 }).map((_, index) => (
                    <TableRow key={index} style={{ textAlign: "center" }}>
                      {Array.from({ length: columns.length }).map((_, cellIndex) => (
                        <TableCell
                          key={cellIndex}
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={"100%"} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : coinData?.coin?.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={{ textAlign: "center", border: "none" }}
                      onClick={() => handleRowClick(row.id)}
                      className="tableRow"
                    >
                      {columns.map((column) => {
                        const value = column.id === "srNo" ? index + 1 : row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            sx={{
                              textAlign: "center",
                              cursor: "pointer",
                              borderBottom: "none",
                              color: theme.skin === "dark" ? "#fff" : "#000",
                            }}
                          >
                            {column.id === "coin_info" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "start",
                                  minWidth: "200px",
                                  gap: 15,
                                }}
                              >
                                <img
                                  src={row.coin_logo}
                                  alt={row.coin_name}
                                  style={{ width: "35px", height: "35px", marginLeft: "0px" }}
                                />
                                <div style={{ textAlign: "start" }}>
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 700,
                                      color: theme.skin === "dark" ? "#fff" : "#000",
                                    }}
                                  >
                                    {row.coin_name}
                                  </div>
                                  <div style={{ fontSize: "12px", color: "#999999" }}>{row.coin_symbol}</div>
                                </div>
                              </div>
                            ) : column.id === "graph" ? (
                              renderGraph() // Replace with actual graph component
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={searchKeyword ? coinData?.coin.length : coinData?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        {/* </Paper> */}
      </div>
    </div>
  );
};

export default CryptoTable;
