import classNames from "classnames";
import { useContext, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Buy from "./Buy";
import Sell from "./Sell";
import { postRequest } from "../../../api-service";
import Swal from "sweetalert2";
import { ReviewContext } from "../../../App";

const BuySellModal = ({ isOpen, close }) => {
  const { getValueForNewData } = useContext(ReviewContext);

  const { coinId } = useParams();
  // console.log("coinId", coinId);

  const [activeTab, setActivetab] = useState("1");
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);

  const [feedback, setFeedback] = useState("");

  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  localStorage.setItem("reviewSubmittedForNewData", reviewSubmitted);

  const handleRatingSubmit = () => {
    // Here you can send ratingValue and feedback to the backend
    console.log("Rating:", ratingValue);
    console.log("review:", feedback);
    // Reset values after submission
    setRatingValue(0);
    setFeedback("");

    const payload = {
      coin_id: coinId,
      rating: ratingValue,
      review: feedback,
    };

    const res = postRequest("/coin/submit-review", payload)
      .then((response) => {
        // console.log("response.data.status", response.status);
        if (response.status === true) {
          Swal.fire({
            icon: "success",
            title: `${response.message}`,
            // text: "Your investment has been made successfully",
            focusConfirm: false,
            timer: 1500,
            showConfirmButton: false,
          });
          // console.log("resultSucceess", res);
          setShowRatingModal(false);
          // window.location.reload();
          setReviewSubmitted(!reviewSubmitted);
          getValueForNewData(reviewSubmitted);
        } else {
          Swal.fire({
            icon: "error",
            title: `${response.errors[0]}`,
            // text: "Something went wrong with your investment",
            focusConfirm: false,
            timer: 1500,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        console.log("resultError", error);
        // Close the rating modal
        setShowRatingModal(false);
      });
  };

  const renderRatingStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => setRatingValue(i)}
          style={{ cursor: "pointer", color: i <= ratingValue ? "#ffe736" : "gray", fontSize: "40px" }}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const handleCloseModal = () => {
    close(); // Close the modal
    setShowRatingModal(true); // Show the rating modal
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>{`Subscribe Now`}</ModalHeader>
        <ModalBody className="pt-0">
          {/* <ul class="nav nav-tabs nav-tabs-s2">
            <li class="nav-item" onClick={() => setActivetab("1")}>
              <a class={`nav-link ${activeTab === "1" ? "active" : ""}`} data-bs-toggle="tab" href="#tabItem9">
                Invest Now
              </a>
            </li>
            <li class="nav-item" onClick={() => setActivetab("2")}>
              <a class={`nav-link ${activeTab === "2" ? "active" : ""}`} data-bs-toggle="tab" href="#tabItem10">
                Sell Coins
              </a>
            </li>
          </ul> */}
          <Buy handleCloseModal={handleCloseModal} />
          {/* <div class="tab-content">
            <div class={`tab-pane ${activeTab === "1" ? "active" : ""}`}>
            </div>
            <div class={`tab-pane ${activeTab === "2" ? "active" : ""}`}>
              <Sell />
            </div>
          </div> */}
        </ModalBody>
      </Modal>

      <Modal isOpen={showRatingModal} toggle={() => setShowRatingModal(false)} className="modal-dialog-slideout">
        <ModalHeader toggle={() => setShowRatingModal(false)}>Rating and review</ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p className="mb-0">Rate your experience:</p>
            {renderRatingStars()}
          </div>
          <div className="mb-3">
            <label htmlFor="feedback" className="form-label">
              Review
            </label>
            <textarea
              placeholder="Write your experience"
              className="form-control"
              id="feedback"
              rows="3"
              value={feedback}
              maxLength={800}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              disabled={feedback && ratingValue ? false : true}
              className="btn btn-primary"
              onClick={handleRatingSubmit}
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default BuySellModal;
