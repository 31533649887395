import React, { useEffect, useState } from "react";
import classNames from "classnames";
import AppDropdown from "./dropdown/app/App";
import User from "./dropdown/user/User";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import logo from "../../assets/logo.png";
import logoDark from "../../assets/logo-dark.png";
import coinExchange from "../../assets/Coin Exchange.png";
import coinExchangeDark from "../../assets/coinExchangeDark.png";

import { Search, PieChart } from "lucide-react";
import { Container } from "reactstrap";
import { fetchAPI, getCookie } from "../../utils/Utils";
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { getRequest } from "../../api-service";
import { Icon } from "../../components/Component";
import Notification from "./dropdown/notification/Notification";

const Header = ({ fixed, className }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const navigate = useNavigate();
  const location = useLocation();

  const [authenticated, setAuthenticated] = useState(false); // State to manage user authentication status

  const [data, setCoinData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const addToPortfolioHandler = (id) => {
    window.location.href = `/companies/${id}`;
  };

  const getCoinsData = () => {
    getRequest(`/coin/get-coin?search_keyword=${searchText}`).then((res) => {
      setCoinData(res.data.coin);
    });
  };
  useEffect(() => {
    getCoinsData();
  }, [searchText]);

  useEffect(() => {
    // Check if the user is authenticated (dummy condition, replace it with your actual authentication logic)
    const isAuthenticated = !!getCookie("authToken"); // Example: Check if a user token exists in cookies
    setAuthenticated(isAuthenticated);
  }, []);

  const headerClass = classNames({
    // "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const handleSignIn = () => {
    // Redirect to the account URL for signing in
    window.location = process.env.REACT_APP_ACCOUNT_URL;
  };

  const handleOptionSelected = (event, value) => {
    if (value) {
      addToPortfolioHandler(value.id);
    }
  };

  const [fullScreenOn, setFullScreenOn] = useState(false);

  // useEffect(() => {
  //   const fetchTheme = async () => {
  //     try {
  //       const data = new FormData();
  //       data.append("action", "fetch");
  //       const response = await fetchAPI(data, "public/frontend-settings");
  //       if (response.status) {
  //         const fetchedTheme = response?.data?.frontend_settings?.theme;
  //         themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchTheme();
  // }, [fullScreenOn]);
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  return (
    <div
      className={headerClass}
      style={{ backgroundColor: theme.skin === "dark" ? "#000" : "#fff", borderBottom: "0.5px solid #f0efed" }}
    >
      {/* <Container className="px-md-4"> */}
      <div
        className="container-fluid"
        style={{ paddingTop: "-10px", height: "fit-content", zIndex: 1500, border: "none" }}
      >
        <div className="nk-header-wrap" style={{ height: "52px", borderBottom: "none", paddingBottom: "-10px" }}>
          <div className="nk-header-app-name " style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            <div className="d-flex gap-5">
              <img
                src={theme.skin === "dark" ? coinExchangeDark : coinExchange}
                alt=""
                height={isSmallScreen ? 38 : 45}
                width={isSmallScreen ? 38 : 45}
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="ml-2" style={{ marginLeft: "2%", color: theme.skin === "dark" ? "#fff" : "#000" }}>
              <p style={{ marginTop: "0%", fontWeight: "800", fontSize: isSmallScreen ? "13px" : "16px" }}>
                CoinExchange
              </p>
              <p style={{ marginTop: isSmallScreen ? "-22px" : "-20%", fontSize: isSmallScreen ? "12px" : "12px" }}>
                by SiloCloud
              </p>
            </div>
          </div>
          <div className="nk-header-menu is-light">
            <div className="nk-header-menu-inner">
              <ul className="nk-menu nk-menu-main">
                <li style={{ color: location.pathname === "/" ? "red" : "" }} className={`nk-menu-item fs-14 `}>
                  <Link to={`${process.env.PUBLIC_URL}/`} className="nk-menu-link">
                    <span className="nk-menu-text fs-13">Companies</span>
                  </Link>
                </li>
                {/* <li className={`nk-menu-item ${location.pathname === process.env.PUBLIC_URL + "/forms" ? "active current-page" : ""}`}>
                    <Link to={`${process.env.PUBLIC_URL}/forms`} className="nk-menu-link">
                      <span className="nk-menu-text">Forums</span>
                    </Link>
                  </li> */}
              </ul>
            </div>
          </div>

          <div
            className={`${location === "/" ? "d-none" : "d-none"} justify-content-center align-items-center m-auto`}
            style={{ display: location === "/" ? "none" : "none" }}
          >
            <Autocomplete
              fullWidth
              id="combo-box-demo"
              options={data}
              getOptionLabel={(option) => option.coin_name}
              onChange={handleOptionSelected}
              sx={{ width: 200, borderRadius: "24px" }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Search Coin"
                  // onChange={(e) => setSearchText(e.target.value)}
                  sx={{
                    height: "40px", // Set the desired height
                    "& .MuiInputBase-root": {
                      height: "40px", // Set the input field height
                      width: "300px",
                      padding: "10px auto",
                      borderRadius: "24px",
                      // color: "white",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "0.875rem", // Adjust the label font size
                      margin: "-5px auto",
                      borderRadius: "24px",
                      // color: "white",
                    },
                    borderRadius: "24px",
                    // '& .MuiInputLabel-shrink': {
                    //   transform: 'translate(14px, -6px) scale(0.75)', // Adjust the label position when shrunk
                    // },
                  }}
                />
              )}
            />

            {/* <input type="text" className="form-control" placeholder="Search coin" /> */}
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* <li className="list-apps-dropdown d-lg-none">
                  <AppDropdown />
                </li> */}

              {!authenticated ? ( // If user is not authenticated, display sign-in button
                <li className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-primary" onClick={handleSignIn}>
                    Sign In
                  </button>
                </li>
              ) : (
                <>
                  <li className="d-flex justify-content-center align-items-center" style={{ marginTop: "2%" }}>
                    <div className="nk-header-menu is-light">
                      <div
                        className={`nk-header-menu-inner d-flex align-items-center pe-2 py-1 rounded-pill
                         ${location.pathname === "/portfolio" ? "bg-white text-primary " : ""}
                        
                        `}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/portfolio")}
                      >
                        <PieChart className="mx-1" size={14} />
                        <p className="fw-semibold fs-14px">Portfolio</p>
                      </div>
                    </div>
                  </li>

                  <li style={{ marginTop: "3%" }}>
                    {fullScreenOn ? (
                      <Icon name="minimize" className="pointer fs-4 " onClick={toggleFullScreen} />
                    ) : (
                      <Icon name="maximize" className="pointer fs-4 " onClick={toggleFullScreen} />
                    )}
                  </li>
                  <li className="notification-dropdown me-n1">
                    <Notification />
                  </li>
                  <li className="list-apps-dropdown ">
                    <AppDropdown />
                  </li>
                  <li className="d-flex justify-content-center align-items-center">
                    <div className="nk-header-menu is-light">
                      <div className="nk-header-menu-inner pointer">
                        {/* <h6 className="me-2">{getCookie("username")}</h6> */}
                      </div>
                    </div>
                    <User />
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </div>
  );
};
export default Header;
