// import { useEffect, useState } from "react";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import { getRequest } from "../../../../api-service";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "../../../../layout/provider/Theme";

const Video = ({ coinDetails }) => {
  const { coinId } = useParams();
  const isSmallScreen = useMediaQuery("(max-width:400px)");
  const theme = useTheme();

  // const VIDEO_PATH = "https://api.silocloud.io/api/v1/public/channel-video";
  const [videoURL, setVideoURL] = useState();
  // console.log('video url',videoURL)

  const getVideoURLPath = () => {
    getRequest(`/coin/get-coin-video?coin_id=${coinId}`).then((response) => {
      // console.log('response', response);
      setVideoURL(response.data.video_url);
    });
  };

  useEffect(() => {
    getVideoURLPath();
    // console.log('videoURL', videoURL);
  }, [coinId]);

  return (
    <>
      {coinDetails?.map((coin) => (
        <div
          // color="light"
          // className={` ${isScrolling ? "navbar-transition" : ""}`}
          light
          expand="md"
          style={{
            color: theme.skin === "dark" ? "#fff" : "#000",

            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
            position: "fixed",
            minWidth: isSmallScreen ? "84%" : "90%",
            marginLeft: "-0.8%",
            fontSize: isSmallScreen ? "0.5rem" : "",
            borderBottom: "1px solid #c9bcbb",
            // backgroundColor: isScrolling ? "rgba(250, 245, 245,0.9)" : "#fff",
            zIndex: 1,
            marginTop: "-0.35%",
            // padding: "10px",
            paddingBottom: "0",
          }}
        >
          <div
            className={`${isSmallScreen ? "d-block" : "d-flex"}  justify-content-start`}
            style={{
              color: theme.skin === "dark" ? "#fff" : "#000",
              fontSize: "18px",
              fontWeight: "600",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div className="d-flex">
              <img src={coin.coin_logo} alt="" height={40} width={40} />
              <p style={{ minWidth: "fit-content", fontSize: "22px", marginLeft: "10px" }}>{coin.coin_name}</p>
            </div>
            <p style={{ margin: isSmallScreen ? "-4% 21%" : "5px 1%" }}>
              <span style={{ fontSize: "14px" }}>$</span>
              {coin.price}
            </p>
          </div>
        </div>
      ))}
      <p className="mb-4" style={{ marginBottom: "15px" }}>
        .
      </p>
      <div className="d-flex  justify-content-center w-100 mt-4" style={{ marginTop: "15px" }}>
        <ReactPlayer url={videoURL} controls={true} style={{ marginTop: "30px", paddingTop: "0px" }} />
      </div>
    </>
  );
};
export default Video;
