import React from "react";

function Sparkline({ data, width, height, color }) {
  const svg = (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
      <polyline
        points={data.map((value, i) => `${(i * width) / (data.length - 1)},${height - value}`).join(" ")}
        fill="none"
        stroke={color}
        strokeWidth="1.25"
      />
    </svg>
  );

  return <div>{svg}</div>;
}

export default Sparkline;
