import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import DetailedNews from "./DetailedNews";
import { postRequest } from "../../../../api-service";
import axios from "axios";
import { useParams } from "react-router";
import noData from "../../../../images/Coin.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "../../../../layout/provider/Theme";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month; // Prefix single digit months with a '0'
  }
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day; // Prefix single digit days with a '0'
  }
  return `${year}-${month}-${day}`;
};

const NewsCard = ({ title, imageSrc, description, source, publishedAt }) => {
  return (
    <div className="card border m-2" style={{ cursor: "pointer", minHeight: "350px" }}>
      <div className="text-center pt-2">
        <img src={imageSrc} className="card-img-top" alt="News" style={{ maxHeight: "200px", maxWidth: "325px" }} />
      </div>
      <div className="card-body">
        <h5 className="card-title limit-1">{title}</h5>
        <p className="card-text limit-2">{description}</p>
        <p className="card-text">
          <small className="text-muted">
            {source} - {formatDate(publishedAt)}
          </small>
        </p>
      </div>
    </div>
  );
};

const News = ({ coinDetails }) => {
  const theme = useTheme();
  const { coinId } = useParams();
  const isSmallScreen = useMediaQuery("(max-width:400px)");
  const isSmallScreen1 = useMediaQuery("(max-width:700px)");

  let IMG_URL = "https://www.4cornerresources.com/wp-content/uploads/2023/09/Blockchain-in-hiring.png";

  const [newsData1, setNewsData1] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [detailedNews, setDetailedNews] = useState(null);

  const getNewsData = () => {
    const payload = {
      coin_id: coinId,
      // coin_id: 11,
      page: currentPage,
    };
    axios.post("https://api.silocloud.io/api/v1/coin/get-coin-news", payload).then((res) => {
      setNewsData1(res.data.data.News);
      setTotalPages(res.data.data.News ? Math.ceil(res.data.data.News?.length / 4) : 1); // Assuming 4 news per page
    });
  };

  useEffect(() => {
    getNewsData();
  }, [currentPage]);

  const close = () => setDetailedNews(null);

  if (detailedNews) {
    return <DetailedNews data={detailedNews} close={close} />;
  }

  return (
    <div className="">
      {coinDetails?.map((coin) => (
        <div
          // color="light"
          // className={` ${isScrolling ? "navbar-transition" : ""}`}
          light
          expand="md"
          style={{
            color: theme.skin === "dark" ? "#fff" : "#000",

            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
            position: "fixed",
            minWidth: isSmallScreen ? "84%" : "90%",
            marginLeft: "-0.8%",
            fontSize: isSmallScreen ? "0.5rem" : "",
            borderBottom: "1px solid #c9bcbb",
            // backgroundColor: isScrolling ? "rgba(250, 245, 245,0.9)" : "#fff",
            zIndex: 1,
            marginTop: "-0.35%",
            // padding: "10px",
            paddingBottom: "0",
          }}
        >
          <div
            className={`${isSmallScreen ? "d-block" : "d-flex"}  justify-content-start`}
            style={{
              color: theme.skin === "dark" ? "#fff" : "#000",
              fontSize: "18px",
              fontWeight: "600",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div className="d-flex">
              <img src={coin.coin_logo} alt="" height={40} width={40} />
              <p style={{ minWidth: "fit-content", fontSize: "22px", marginLeft: "10px" }}>{coin.coin_name}</p>
            </div>
            <p style={{ margin: isSmallScreen ? "-4% 21%" : "5px 1%" }}>
              <span style={{ fontSize: "14px" }}>$</span>
              {coin.price}
            </p>
          </div>
        </div>
      ))}
      <p className="mb-4 mt-4" style={{ marginBottom: "25px" }}>
        .
      </p>

      <div className="row mt-4 p-4">
        {Array.isArray(newsData1) && newsData1?.length > 0 ? (
          newsData1.map((newsItem, index) => (
            <div className="col-md-4" key={index} onClick={() => setDetailedNews(newsItem)}>
              <NewsCard
                title={newsItem.title ? newsItem.title : ""}
                imageSrc={newsItem.news_img ? newsItem.news_img : ""}
                description={newsItem.description ? newsItem.description : ""}
                source={newsItem.author ? newsItem.author : ""}
                publishedAt={newsItem.updated_at ? newsItem.updated_at : ""}
              />
            </div>
          ))
        ) : (
          <div className="text-center" style={{ marginTop: isSmallScreen1 ? "40%" : "10%" }}>
            <img src={noData} alt="No data found" height={100} width={100} />
            <p className="mt-2 font-bold">
              <b>Oops! It's Empty</b>
            </p>
            <p style={{ marginTop: "-15px" }}>No news available for this coin.</p>
          </div>
        )}
      </div>

      {/* <div className="px-2 d-flex justify-content-center" style={{ marginTop: "57vh" }}>
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              href="#prev"
              onClick={(ev) => {
                ev.preventDefault();
                setCurrentPage((prevPage) => prevPage - 1);
              }}
              aria-label="Prev"
            >
              Prev
            </PaginationLink>
          </PaginationItem>
          {[...Array(5).keys()].map((offset) => {
            const page = currentPage + offset;
            if (page >= 1 && page <= totalPages) {
              return (
                <PaginationItem key={page} active={page === currentPage}>
                  <PaginationLink
                    href="#item"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setCurrentPage(page);
                    }}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              );
            }
            return null;
          })}
          <PaginationItem disabled={currentPage * 4 >= newsData1?.length}>
            <PaginationLink
              href="#next"
              onClick={(ev) => {
                ev.preventDefault();
                setCurrentPage((prevPage) => prevPage + 1);
              }}
              aria-label="Next"
            >
              Next
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div> */}
    </div>
  );
};
export default News;
