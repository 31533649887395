import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { Container } from "reactstrap";

const Layout = ({ title, app, ...props }) => {
  return (
    <>
      <>
        <Head title={!title ? "Loading" : title} />
        <AppRoot>
          <AppMain>
            <AppWrap>
              <div className="px-md-4" style={{ minHeight: "100vh" }}>
                <Header fixed />
                <Outlet />
              </div>
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </>
    </>
  );
};
export default Layout;
