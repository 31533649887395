import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const currentYear = new Date().getFullYear();
  return (
    <div className="nk-footer mt-2" style={{ width: isSmallScreen ? "95vw" : "100%" }}>
      {/* <Container className="px-md-4"> */}
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright"> © {currentYear} CoinExchange</div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                {/* <Link to={`${process.env.PUBLIC_URL}/pages/terms-policy`} className="nav-link"> */}
                <Link to={`https://silocloud.io/terms-condition`} className="nav-link">
                  Terms
                </Link>
              </li>
              <li className="nav-item">
                {/* <Link to={`${process.env.PUBLIC_URL}/pages/faq`} className="nav-link"> */}
                <Link to={`https://silocloud.io/privacy-policy`} className="nav-link">
                  Privacy
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to={`${process.env.PUBLIC_URL}/pages/terms-policy`} className="nav-link">
                  Help
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </div>
  );
};
export default Footer;
