import { DollarSign } from "lucide-react";
import { Button, Input, Label } from "reactstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useNavigate, useParams } from "react-router";
import { useQuery } from "react-query";
import { getCookie } from "../../../utils/Utils";
import { getRequest, postRequest } from "../../../../src/api-service";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import Loader from "../../../Loader";
import { Icon } from "../../../components/Component";
import { ReviewContext } from "../../../App";

const baseUrl = "https://api.silocloud.io/api/v1"; // Base url

const token =
  getCookie("authToken") ||
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTQwMTY3MjksImlhdCI6MTcxMzkzMDMyOSwianRpIjoiYWM0MWVhNzg5MmRmNjJjNzI4YjA0MWFkY2YxZTFhYmUiLCJ1c2VybmFtZSI6InhhaW8iLCJlbWFpbCI6ImhvbGljODYwMzFAZG92ZXNpbG8uY29tIiwiY2xvdWRVc2VySWQiOjMsInJvbGUiOnsiaWQiOjIsIm5hbWUiOiJBZG1pbmlzdHJhdG9yIiwia2V5IjoiYWRtaW4ifX0.OIBvVmMresJd72FFtbjkIdiu5z5wCOx9lHnQa49IAnI";

const COINDETAIL = "coinDetail";

const Buy = ({ handleCloseModal }) => {
  const formatNumber = (num) => {
    return num ? num.toLocaleString() : "";
  };
  const { getValueForNewData } = useContext(ReviewContext);

  const { coinId } = useParams();
  const navigate = useNavigate();

  const [investmentAmt, setInvestmentAmt] = useState(null);
  // console.log("investmentAmt", investmentAmt);

  const [showPayPalButtons, setShowPayPalButtons] = useState(false);
  const [showTokentransaction, setShowTokentransaction] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("accountToken");
  const [tokensData, setTokensData] = useState({});
  const [coinData, setCoinData] = useState([]);
  const [open, setOpen] = useState(false);

  // console.log("tokensData", tokensData.available_tokens);

  const [quantity, setQuantity] = useState({
    year_id: 1,
    coin_id: parseInt(coinId, 10),
    investment_amount: null,
  });
  // console.log(quantity);

  const { data, isError, isLoading } = useQuery({
    queryKey: [COINDETAIL, coinId],
    queryFn: async () => {
      let response = await axios.get(`${baseUrl}/coin/get-coin?coin_id=${coinId}`);
      if (response.data.type === "success") {
        console.log("objectobjectobjectobject", response);
        setCoinData(response.data.data.coin[0]);
        return response.data.data;
      } else if (response.data.type === "error") {
        return Promise.reject("Something Went Wrong!!");
      }
    },
  });

  const getTokensData = () => {
    const payload = {
      investment_amount: quantity.investment_amount,
    };
    postRequest("/coin/get-token-value", payload).then((res) => {
      // console.log("Captured token data:", res.data);
      setTokensData(res?.data);
    });
  };

  const initialOptions = {
    "client-id": "AQZbi5QAbfR6FkoAXQoR8vTqMqtVvXofmVwbWytgl43EvLpKEsyuWg42DaUXgh20brjZDICBz7lR46fV",
    currency: "USD",
    intent: "capture",
  };

  // const createOrder = (data, actions) => {
  //    console.log(quantity.investment_amount);
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           currency_code: "USD",
  //           value: investmentAmt,
  //         },
  //       },
  //     ],
  //   });
  // };
  const purchaseAmount = (parseFloat(investmentAmt) + parseFloat((investmentAmt * tokensData.auger_fee) / 100)).toFixed(
    2
  );
  console.log("investmentAmt amt", investmentAmt);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: purchaseAmount,
          },
        },
      ],
    });
  };

  const paymentDoneAPI = () => {
    // let totalPaypalAmt = (quantity.investment_amount * tokensData.auger_fee) / 100 + quantity.investment_amount;
    // console.log("totalPaypalAmt", totalPaypalAmt);
    const payload = {
      year_id: quantity.year_id,
      coin_id: quantity.coin_id,
      investment_amount: quantity.investment_amount,
    };
    // console.log("Payload sent to backend:", payload);
    setOpen(true);

    postRequest("/coin/make-investment", payload)
      .then((res) => {
        // console.log("Captured investment amount:", res.data);
        setOpen(false);
        getValueForNewData(open);

        Swal.fire({
          icon: "success",
          title: `${res.message}`,
          text: "Your subscription has been made successfully",
          focusConfirm: false,
          timer: 1500,
          showConfirmButton: false,
        });
        // console.log("resultSucceess", res);
      })
      .catch((error) => {
        console.log("resultError", error);
        setOpen(false);

        Swal.fire({
          icon: "error",
          title: "Investment Failed",
          text: "Something went wrong with your investment",
          focusConfirm: false,
          timer: 1500,
          showConfirmButton: false,
        });
      });
    handleCloseModal();
    setQuantity({ ...quantity, investment_amount: null });
    setInvestmentAmt(null);
  };

  const onApprove = (data, actions) => {
    setOpen(true);

    return actions.order.capture().then(function (details) {
      console.log("details for PayPal payment", details);
      const payload = {
        purpose: 1,
        mode: 2,
        buyTokens: 1,
        payment_response: JSON.stringify(details),
      };

      postRequest(`/payment/add`, payload)
        .then((res) => {
          console.log("make invest call after add", res);
          setOpen(false);

          if (res.status === true) {
            console.log("Payment added successfully, calling paymentDoneAPI");
            paymentDoneAPI();
          }
        })
        .catch((error) => {
          setOpen(false);

          console.error("Error in /payment/add API call", error);
        });

      // console.log("Captured investment amount:", quantity.investment_amount);
    });
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  if (isError) {
    return <>Error...</>;
  }

  const coin = data.coin;

  const handleChange = (e) => {
    const newValue = e.target.value;
    // console.log("New investment amount entered:", newValue); // Log input value
    setQuantity((prevState) => ({ ...prevState, investment_amount: newValue }));
    setInvestmentAmt(newValue);
  };
  const handleShowTokensTransaction = async () => {
    setShowTokentransaction(true);
    getTokensData();
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const handlePaymentDoneWithTokens = (event) => {
    paymentDoneAPI();
  };

  const abc = true;

  return (
    <>
      <div>
        {showTokentransaction === false && (
          <div>
            {coin?.map((element) => (
              <div
                className="d-flex justify-content-start align-items-center responsiveFlex mt-4"
                key={element.coin_id}
              >
                <img src={element.coin_logo} alt="logo" width={50} />
                <div className="ms-2">
                  <h4 className="m-0 p-0">{element.coin_name}</h4>
                  <p className="m-0 p-0">{element.coin_symbol}</p>
                </div>
              </div>
            ))}

            <hr />

            <div>
              <Label>How much do you want to Subscribe($)?</Label>
              <Input
                type="number"
                placeholder="Enter Subscription amount $"
                value={quantity.investment_amount}
                // value={investmentAmt}
                onChange={handleChange}
              />
              {quantity.investment_amount < 10 && (
                <p className="text-primary">The minimum subscription amount required is $10 USD.</p>
              )}
            </div>

            <div style={{ width: "100%" }} className="my-4">
              <Label>Notes (Optional)</Label>
              <Input placeholder="Enter notes" />
            </div>

            {/* {quantity.investment_amount > 0 ? (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
              onError={(err) => console.log(err)}
              fundingSource="paypal"
            />
          </PayPalScriptProvider>
        ) : (
          <Button
            color="primary"
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            <DollarSign size={18} className="me-1" /> <span>Buy now</span>
          </Button>
        )} */}

            {quantity.investment_amount >= 10 && (
              <Button
                color="primary"
                style={{ width: "100%", textAlign: "center", justifyContent: "center" }}
                // onClick={() => setShowPayPalButtons(true)}
                onClick={handleShowTokensTransaction}
              >
                {/* <DollarSign size={18} className="me-1" />  */}
                <span>Buy Now</span>
              </Button>
            )}

            {/* {quantity.investment_amount > 0 && (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={(err) => console.log(err)}
              fundingSource="paypal"
            />
          </PayPalScriptProvider>
        )} */}
          </div>
        )}

        {showTokentransaction && (
          <>
            <div style={{ padding: "10px 0", display: "flex", justifyContent: "space-between" }}>
              <Typography>Coin Name: {coinData.coin_name}</Typography>
              <Button
                color="primary"
                size="sm"
                style={{ height: "25px" }}
                onClick={() => setShowTokentransaction(false)}
              >
                {/* Back */}
                <Icon className="ni ni-first" />
              </Button>
            </div>
            <Divider
              orientation="horizontal"
              sx={{
                color: "black",
                textAlign: "start",
                marginLeft: "-5%",
                width: "110.2%",
                borderBottom: "1px solid black",
                opacity: "0.1",
              }}
            />

            {/* Radio buttons in one line */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl component="fieldset" style={{ marginRight: "20px" }}>
                <RadioGroup
                  aria-label="payment-method"
                  name="payment-method"
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                >
                  <FormControlLabel value="paypal" control={<Radio color="error" />} label="Paypal" />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="payment-method"
                  name="payment-method"
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                >
                  <FormControlLabel value="accountToken" control={<Radio color="error" />} label="Account Token" />
                </RadioGroup>
              </FormControl>
            </div>

            {/* Fields based on radio button selection */}
            {paymentMethod === "paypal" && (
              <div>
                <div style={{ padding: "10px 0", textAlign: "center" }}>
                  <Typography>Live Token Rate 1 SBC ≈ ${tokensData?.token_value.toFixed(6)}</Typography>
                  <Typography>Purchase Amount($): ${formatNumber(purchaseAmount)}</Typography>
                  <div style={{ height: "25px", width: "184px", textAlign: "center", margin: "20px auto" }}>
                    {quantity.investment_amount > 0 && (
                      <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={(err) => console.log(err)}
                          fundingSource="paypal"
                        />
                      </PayPalScriptProvider>
                    )}
                  </div>
                </div>{" "}
                {/* Add your Paypal fields here */}
              </div>
            )}

            {paymentMethod === "accountToken" && tokensData && (
              <div style={{ padding: "0 10px" }}>
                <div style={{ padding: "10px 0", display: "flex", justifyContent: "space-between" }}>
                  <Typography>
                    Available Tokens:{" "}
                    {tokensData.available_tokens
                      ? formatNumber(tokensData.available_tokens)
                      : tokensData.available_tokens === 0
                      ? "0"
                      : formatNumber(tokensData.available_tokens)}
                  </Typography>
                  <Typography sx={{ ml: 1 }}>Auger Fee e(2.9%): {formatNumber(tokensData.augerTokens)}</Typography>
                </div>
                <div style={{ padding: "10px 0", display: "flex", justifyContent: "space-between" }}>
                  <Typography>
                    Required Tokens:
                    {tokensData.required_tokens
                      ? formatNumber(tokensData.required_tokens)
                      : tokensData.required_tokens === 0
                      ? "0"
                      : formatNumber(tokensData.required_tokens)}
                  </Typography>
                  {/* <Typography>Auger Fee (2.9%): ${investmentAmt}</Typography> */}
                </div>
              </div>
            )}

            <Divider
              orientation="horizontal"
              sx={{
                color: "black",
                textAlign: "start",
                marginLeft: "-5%",
                width: "110.2%",
                borderBottom: "1px solid black",
                opacity: "0.1",
              }}
            />
            <div style={{ padding: "5px 0 0 0 ", display: "flex", justifyContent: "end" }}>
              {/* <Typography>Product Name: ${investmentAmt}</Typography> */}

              {tokensData.available_tokens >= tokensData.required_tokens ? (
                <Button
                  style={{ display: paymentMethod === "paypal" ? "none" : "flex" }}
                  color="primary"
                  size="small"
                  value="paypal"
                  onClick={handlePaymentDoneWithTokens}
                >
                  Pay with Tokens
                </Button>
              ) : (
                <Button
                  style={{ display: paymentMethod === "paypal" ? "none" : "flex" }}
                  color="primary"
                  size="small"
                  value="paypal"
                  onClick={handlePaymentMethodChange}
                >
                  Purchase Token
                </Button>
              )}
            </div>

            <Backdrop sx={{ color: "red", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        )}
      </div>
    </>
  );
};

export default Buy;
